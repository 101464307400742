<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>主题列表</span>
        <el-button
          type="success"
          icon="el-icon-plus"
          @click="add"
          v-if="$check(51)"
          style="float: right; position: relative; bottom: 10px"
          >添加</el-button
        >
        <el-button
          type="primary"
          @click="query(1)"
          style="float: right; margin-right: 10px; position: relative; bottom: 10px"
          icon="el-icon-search"
          >查询</el-button
        >
        <el-input
          v-model="filterData.search"
          placeholder="输入关键字搜索"
          clearable
          style="
            float: right;
            width: 250px;
            margin-right: 10px;
            position: relative;
            bottom: 10px;
          "
        ></el-input>
      </div>
      <el-table v-loading="loading" :data="tableData" style="width: 100%" size="mini" :cell-style="{paddingTop: '4px', paddingBottom: '0px'}">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="sort" width="100" label="Order">
          <template slot-scope="scope">
            <el-input size='mini'
              @change="chSort(scope.row)"
              v-model="scope.row.sort"
              type="number"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" width="55"></el-table-column>
        <el-table-column prop="description" label="Type">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.tag == 0" type="success">Wooo Series</el-tag>
            <el-tag v-else>Wooo Select</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="Title"></el-table-column>
        <el-table-column prop="approvedFor" label="Approved For"></el-table-column>
        <el-table-column prop="category" width="70" label="Cover">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.index_image"
              style="height: 40px;"
            ></el-image>
          </template>
        </el-table-column>
       
        <el-table-column prop="on_time" label="Live Date">
          <template slot-scope="scope">
            {{ formatDateString(scope.row.on_time, true) }}
          </template>
        </el-table-column>

         <el-table-column label="View products">
           <template slot-scope="scope">
            <router-link :to="{ path: scope.row.tag === 0 ? '/products/list/series' : '/products/list/select', query: { theme_id: scope.row.id}}">
              {{ scope.row.products_count }}
            </router-link>
          </template>
         </el-table-column>

        <el-table-column label="Actions" width="200">
          <template slot-scope="scope">
            <i class="el-icon-edit item primary" @click="edit(scope.row)"></i>
            <i class="el-icon-document-copy item" @click="duplicate(scope.row)"></i>
            <i class="el-icon-delete item danger" @click="remove(scope.row.id)"></i>
            <el-button size="small" type="danger" @click="notify(scope.row.id)">Notify</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col :span="24" style="text-align: center; margin-top: 10px">
          <el-pagination
            background
            layout="total,prev, pager, next"
            @current-change="pageChange"
            :current-page="filterData.page"
            :total="total"
          ></el-pagination>
        </el-col>
      </el-row>
    </el-card>

    <el-dialog
      center
      fullscreen
      :title="title + ' drop'"
      :visible.sync="dialogFormVisible"
    >
      <el-card v-if="dialogFormVisible">
        <!-- <div slot="header">
          <span>添加分类</span>
        </div> -->
        <el-form :model="form" ref="form" label-width="120px">
          <el-row>
            <el-col :span="8">
              <el-radio-group :disabled='disableApprovedFor' v-model='form.approved_for'>
                <el-radio label='intl'>Approved for .com</el-radio>
                <el-radio label='cn'>Approved for .cn</el-radio>
              </el-radio-group>
            </el-col>

            <el-col :span="16">
              <el-form-item
                label="Type"
                prop="tag"
                :rules="[{ required: true, message: '请选择主题分类' }]"
              >
                <el-radio-group v-model="form.tag">
                  <el-radio :label="0">Wooo Series</el-radio>
                  <el-radio :label="1">Wooo Select</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item
                label="Live At"
                prop="on_time"
                :rules="[{ required: true, message: '请选择主题分类' }]"
              >
                <el-date-picker
                  v-model="form.on_time"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            label="slug"
            prop="slug"
            :rules="[{ required: true, message: '主题标题不能为空' }]"
          >
            <el-input v-model="form.slug" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item
            label="title"
            prop="title"
            :rules="[{ required: true, message: '主题标题不能为空' }]"
          >
            <el-input v-model="form.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="description"
            prop="description"
            :rules="[{ required: true, message: '主题介绍不能为空' }]"
          >
            <el-input
              type="textarea"
              :rows="3"
              v-model="form.description"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="Mobile banner (1:1)"
                prop="index_image"
                :rules="[{ required: true, message: '主题封面不能为空' }]"
              >
                <ImageUpload v-model="form.index_image"></ImageUpload>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item
                label="Desktop banner (16:9)"
                prop="video_image"
                :rules="[{ required: true, message: '主题封面不能为空' }]"
              >
                <ImageUpload v-model="form.video_image"></ImageUpload>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item
            label="Video (optional)"
            prop="video"
            :rules="[{ message: '视频不能为空' }]"
          >
            <ImageUpload accept="video/*" v-model="form.video"></ImageUpload>
          </el-form-item>
            </el-col>
          </el-row>
      
          <el-form-item
            label="SEO Title"
            prop="meta_title"
          >
            <el-input v-model="form.meta_title" autocomplete="off"></el-input>
          </el-form-item>
             <el-form-item
            label="SEO Description"
            prop="meta_description"
          >
            <el-input v-model="form.meta_description" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="submit()">Save</el-button>
      </div>
    </el-dialog>

    <el-dialog center fullscreen title="商品管理" :visible.sync="dialogFormVisible1">
      <el-card>
        <div slot="header">
          <!-- <el-card>
            <div slot="header">品牌</div>
            <el-checkbox-group
              v-model="checkedStores"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox
                border
                v-for="store in stores"
                :label="store.name"
                :key="store.id"
                >{{ store.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-card> -->
        </div>
        <el-table
          @selection-change="handleSelectionChange"
          :data="tableData1"
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="store.name" label="store"></el-table-column>
          <el-table-column prop="brand" label="brand"></el-table-column>
          <el-table-column prop="product_type" label="category"></el-table-column>
          <el-table-column prop="title" label="title"></el-table-column>
          <el-table-column label="Images">
            <template slot-scope="scope">
              <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.images[0]"
                fit="contain"
              ></el-image>
              <p style="text-align: center">共计{{ scope.row.images.length }}张</p>
            </template>
          </el-table-column>

          <el-table-column prop="stock" label="库存"> </el-table-column>
          <el-table-column prop="price" label="价格"> </el-table-column>
          <el-table-column prop="size" label="尺码"> </el-table-column>
          <el-table-column prop="color" label="颜色"> </el-table-column>
          <el-table-column prop="material" label="材料"> </el-table-column>
        </el-table>
        <el-row>
          <el-col :span="24" style="text-align: center; margin-top: 10px">
            <el-pagination
              background
              layout="total,prev, pager, next"
              @current-change="pageChange1"
              :current-page="filterData1.page"
              :total="total1"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">关 闭</el-button>
        <el-button type="primary" @click="submit1()">添加到主题</el-button>
      </div>
    </el-dialog>

    <el-dialog center fullscreen title="商品管理" :visible.sync="dialogFormVisible2">
      <el-card>
        <!-- <div slot="header">
          
        </div> -->
        <el-table
          @selection-change="handleSelectionChange"
          :data="tableData2"
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="sort" width="100" label="排序">
            <template slot-scope="scope">
              <el-input
                @change="chSort1(scope.row)"
                v-model="scope.row.sort"
                type="number"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="product.product_type"
            label="类型"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="product.brand"
            label="来自品牌"
            width="150"
          ></el-table-column>
          <el-table-column prop="product.title" label="商品标题"></el-table-column>
          <el-table-column label="商品图⽚" width="140">
            <template slot-scope="scope">
              <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.product.images[0]"
                fit="contain"
              ></el-image>
              <p style="width: 100px; text-align: center">
                共计{{ scope.row.product.images.length }}张
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="product.stock" label="库存" width="100">
          </el-table-column>
          <el-table-column prop="product.price" label="价格" width="100">
          </el-table-column>
          <el-table-column prop="product.size" label="尺码" width="150">
          </el-table-column>

          <!-- <el-table-column prop="status" label="首页展示" width="100">
            <template slot-scope="scope">
              <el-switch
                @change="chStatus(scope.row)"
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop="product.color" label="颜色"> </el-table-column>
          <el-table-column prop="product.material" label="材料"> </el-table-column> -->

          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                size="small"
                icon="el-icon-close"
                type="danger"
                @click="remove1(scope.row)"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-row>
          <el-col :span="24" style="text-align: center; margin-top: 10px">
            <el-pagination
              background
              layout="total,prev, pager, next"
              @current-change="pageChange2"
              :current-page="filterData2.page"
              :total="total2"
            ></el-pagination>
          </el-col>
        </el-row>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">关 闭</el-button>
        <el-button type="danger" @click="submit2()">移出主题</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ImageUpload from "../common/ImageUpload.vue";
import formatDateString from '../../lib/formatDateString.ts'
export default {
  components: { ImageUpload },
  data() {
    return {
      items: [],
      title: "添加",
      total: 0,
      total1: 0,
      total2: 0,
      rule: [],
      loading: false,
      checkedStores: [],
      stores: [],
      filterData: { page: 1 },
      filterData1: { page: 1 },
      filterData2: { page: 1 },
      tableData: [],
      tableData1: [],
      tableData2: [],
      dialogFormVisible: false,
      dialogFormVisible1: false,
      dialogFormVisible2: false,
      disableApprovedFor: false,
      form: {},
      row: null,
      labels: { material: "材料", size: "尺寸", color: "颜色" },
      products: [],
    };
  },

  created() {
    this.query();
    // this.query2();
  },

  methods: {
    chStatus(info) {
      this.$http
        .post("/admin/theme/products/save", {
          theme_id: info.theme_id,
          product_id: info.product_id,
          status: info.status,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        });
    },
    chSort(info) {
      this.$http
        .post("/admin/theme/save", {
          id: info.id,
          sort: info.sort,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        });
    },

    chSort1(info) {
      this.$http
        .post("/admin/theme/products/save", {
          theme_id: info.theme_id,
          product_id: info.product_id,
          sort: info.sort,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        });
    },
    handleSelectionChange(val) {
      this.products = val;
    },
    select_products(row) {
      this.row = row;
      this.dialogFormVisible1 = true;
      this.query1();
    },
    get_products(row) {
      this.row = row;
      this.dialogFormVisible2 = true;
      this.query2();
    },
    pageChange(page) {
      this.filterData.page = page;
      this.query();
    },

    pageChange1(page) {
      this.filterData1.page = page;
      this.query1();
    },

    pageChange2(page) {
      this.filterData2.page = page;
      this.query2();
    },
    query(page) {
      this.loading = true
      if (page) this.filterData.page = page;
      this.$http
        .post("/admin/theme/list", this.filterData)
        .then((res) => {
          if (res.status == 200) {
            this.loading = false
            this.tableData = res.data.data;
            this.tableData = this.tableData.map(r => ({ ...r, approvedFor: r.approved_for_intl ? 'Intl' : (r.approved_for_cn ? 'Cn' : '(Default CN)')}))
            this.total = res.data.total;
          }
        })
        .catch(() => {});
    },

    query1(page) {
      if (page) this.filterData1.page = page;
      this.filterData1.tag = this.row.tag;
      this.$http
        .post("/admin/store/products", this.filterData1)
        .then((res) => {
          this.tableData1 = res.data.data;
          this.total1 = res.data.total;
        })
        .catch(() => {});
    },

    query2(page) {
      if (page) this.filterData2.page = page;
      this.filterData2.theme_id = this.row.id;
      this.$http
        .post("/admin/theme/products", this.filterData2)
        .then((res) => {
          this.tableData2 = res.data.data;
          this.total2 = res.data.total;
        })
        .catch(() => {});
    },

    // query2() {
    //   this.$http
    //     .post("/admin/theme/stores", this.filterData)
    //     .then((res) => {
    //       if (res.status == 200) {
    //         this.stores = res.data;
    //       }
    //     })
    //     .catch(() => {});
    // },
    remove(id) {
      this.$confirm("此操作将永久删除该条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http.post("/admin/theme/remove", { id: id }).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.query();
            }
          });
        })
        .catch(() => {});
    },

     notify(id) {
        this.$confirm("是否上线该主题?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
              this.$http.get(`/admin/theme/notify/${id}`).then((res) => {
                if (res.status == 200) {
                  this.$message({
                    message: "上线成功",
                    type: "success",
                  });
                  this.query();
                }
              });
            })
            .catch(() => {});
     },

    remove1(info) {
      this.$confirm("此操作将永久删除该条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/admin/theme/products/remove", {
              theme_id: info.theme_id,
              product_ids: [info.product_id],
            })
            .then((res) => {
              if (res.status == 200) {
                this.$message({
                  message: "移除成功",
                  type: "success",
                });
                this.query2();
              }
            });
        })
        .catch(() => {});
    },

    edit(row) {
      this.title = "Edit"
      this.form = row
      this.disableApprovedFor = true
      if (this.form.approved_for_intl) this.form.approved_for = 'intl'
      if (this.form.approved_for_cn) this.form.approved_for = 'cn'
      this.form.duplicate = false
      this.dialogFormVisible = true;
    },

    duplicate(row) {
      this.title = "Duplicate"
      this.form = row;
      delete this.form.approved_for_intl
      delete this.form.approved_for_cn
      delete this.form.approvedFor
      this.form.duplicate = true
      this.dialogFormVisible = true;
      console.log(this.form)
      //this.disableApprovedFor = false
    },

    add() {
      this.form = {};
      this.title = "Create"
      this.disableApprovedFor = false
      this.dialogFormVisible = true
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.approved_for_intl = this.form.approved_for === 'intl' ? 1 : 0
          this.form.approved_for_cn = this.form.approved_for === 'cn' ? 1 : 0
          delete this.form.approved_for
          delete this.form.approvedFor
          console.log(this.form)
          this.$http.post("/admin/theme/save", this.form).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.shouldDuplicate = false;
              this.dialogFormVisible = false;
              this.form = {};
              this.query();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    submit1() {
      if (this.products.length > 0) {
        let product_ids = [];
        this.products.forEach((item) => {
          product_ids.push(item.id);
        });
        this.$http
          .post("/admin/theme/products/save", {
            theme_id: this.row.id,
            product_ids: product_ids,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.query1();
            }
          });
      } else {
        console.log("error submit!!");
        return false;
      }
    },

    submit2() {
      if (this.products.length > 0) {
        let product_ids = [];
        this.products.forEach((item) => {
          product_ids.push(item.product.id);
        });
        this.$http
          .post("/admin/theme/products/remove", {
            theme_id: this.row.id,
            product_ids: product_ids,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.query2();
            }
          });
      } else {
        console.log("error submit!!");
        return false;
      }
    },
    formatDateString
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.primary {
  color: #409eff;
}

.item {
  font-size: 20px;
  margin: 0 5px;
  cursor: pointer;
}

.warning {
  color: #e6a23c;
}

.danger {
  color: #f56c6c;
}

</style>
